import * as React from 'react'
import { graphql, 
  //Link, 
  navigate 
} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Seo from '../components/seo'
import GatsbyGoogleTag from "../components/gatsby-google-tag"

import TritonDefaultLayout from '../layout/default-layout'
import SectionHero from '../components/sections/SectionHero/SectionHero'

import { 
  Container,
  Segment,
  Item,
  Button,
  Header,
  Pagination,
  Icon,
} from 'semantic-ui-react';

dayjs.extend(utc)

export const Head = ({ location, }) => {
  //console.debug('pages - head', { location, params, data, pageContext });
  // handle page level seo content
  const seo = {
    pageTitle: 'All Events',
    pageDescription: null,
  }
  return (
    <>
      <Seo pagePath={location.pathname} title={seo.pageTitle} description={seo.pageDescription} />
      <GatsbyGoogleTag />
    </>
  )
}

const PaginationIndexEvents = (props) => {
  //console.debug('PaginationIndexEvents - props', props);

  if (typeof window !== 'undefined') {
    document.body.id = 'pages-pagination-index-events';
  }

  const {
    edges,
  } = props.data.allEventCalendarItem;

  const { humanPageNumber, numberOfPages, } = props.pageContext;

  const handlePaginationChange = (e, { activePage }) => {
    //console.debug('PaginationIndexEvents - handlePaginationChange', activePage);
    navigate('/connect/events-calendar/events/'+(activePage !== 1 ? activePage : ''));
  }

  const ItemImage = (props) => {
    const { displayText, eventImage } = props;
    const imageAltText = (displayText ? displayText : '') +' image';
    return (
      eventImage.gatsbyImageData ?
        <GatsbyImage className={'ui image small'} image={getImage(eventImage.gatsbyImageData)} alt={imageAltText} />
      : eventImage.imageAbsoluteUrls && eventImage.imageAbsoluteUrls.length !== 0 ?
        <Item.Image size={'small'} src={eventImage.imageAbsoluteUrls[0]} alt={imageAltText} />
      : eventImage.urls && eventImage.urls.length !== 0 ?
        <Item.Image size={'small'} src={eventImage.urls[0]} alt={imageAltText} />
      :
        null
    )
  }

  return (
    <>
      {/* <Seo title={'All Events'} /> */}
      <TritonDefaultLayout subpage={'events-index'}>
        <SectionHero 
          commonPageSection={{
            "dOMID": "top",
            "textColor": "white",
            "textAlign": "left",
            "backgroundColor": "linear-gradient(225deg, rgb(254, 125, 76) 0%, rgb(224, 111, 126) 11%, rgb(148, 60, 215) 31%, rgb(75, 0, 161) 53%, rgb(93, 44, 208) 72%, rgb(18, 175, 222) 100%)",
          }}
          contentCollection={{
            contentItems: [
              {
                "contentItemId": "4hp70xdbw2ycwvfvhrdvhvmtcb",
                "contentType": "ContentHeadline",
                "displayText": "Events Page Headline",
                "variationType": "h1",
                "textAlign": "center",
                "preheader": "Connect",
                "content": {
                  "html": "Events"
                },
                "subheader": null,
                "maxWidth": "100%"
              }
            ]
          }} 
        />
        <Segment basic className={'events-list'}>
          <Container>
            <Item.Group relaxed={'very'} divided>
              {edges && edges.length !== 0 && edges.map((eventItem, i) => {
                const { displayText, 
                  //path, 
                  eventLocation, eventDate, eventEndDate, eventDescription, eventLink, eventImage, 
                } = eventItem.node;
                return (
                  <Item key={i}>
                    {eventImage ?
                      <ItemImage displayText={eventItem.displayText} eventImage={eventImage} />
                    :
                      null
                    }
                    <Item.Content>
                      {displayText ?
                        <Item.Header>
                          <div className={'site-content-headline'}>
                            <Header as={'h3'} textAlign={'left'}>{displayText}</Header>
                          </div>
                        </Item.Header>
                      :
                        null
                      }
                      {eventDate ?
                        <Item.Meta>
                          <Icon name={'calendar alternate outline'} />
                          {dayjs.utc(eventDate).format('dddd, MMMM DD, YYYY')}
                          {eventEndDate ?
                            ' - '+(dayjs.utc(eventEndDate).format('dddd, MMMM DD, YYYY'))
                          :
                            null
                          }
                        </Item.Meta>
                      :
                        null
                      }
                      {eventLocation ?
                        <Item.Meta >
                          <a href={'https://maps.google.com/?q='+eventLocation} target={'_blank'} rel={'noreferrer'}>
                            <Icon 
                              //circular inverted 
                              name={'map marker alternate'}
                              //size={'small'} 
                            />
                            {eventLocation}
                          </a>
                        </Item.Meta>
                      :
                        null
                      }
                      {eventDescription ?
                        <Item.Description>
                          {eventDescription}
                        </Item.Description>
                      :
                        null
                      }
                      {/* {eventLink ?
                        <Item.Extra>
                          <Button secondary as={'a'} icon={'linkify'} labelPosition={'left'}
                            href={eventLink.url ? eventLink.url : null} 
                            target={'_blank'} rel={'noreferrer'}
                            content={eventLink.text ? eventLink.text : 'Event Link'}
                          />
                        </Item.Extra>
                      :
                        null
                      } */}
                    </Item.Content>
                    {eventLink ?
                      <Button className={'site-content-button outline'}
                        style={{ color: '#230859', borderColor: '#230859' }}
                        as={'a'}
                        href={eventLink.url ? eventLink.url : null}
                        //as={Link}
                        //to={parsedEventDate && path ? ('/event/'+parsedEventDate+'/'+path) : null}
                        icon={'arrow right'}
                        labelPosition={'right'}
                        content={eventLink.text ? eventLink.text : 'View Event Details'}
                        //content={'View Event Details'}
                      />
                    :
                      null
                    }
                  </Item>
                )
              })}
            </Item.Group>
            <Segment basic textAlign={'center'} className={'pagination-wrapper'}>
              <Pagination size={'large'} aria-label={'Pagination Navigation'}
                activePage={humanPageNumber}
                totalPages={numberOfPages}
                onPageChange={handlePaginationChange}
                boundaryRange={1}
                siblingRange={0}
                ellipsisItem={true ? undefined : null}
                firstItem={null}
                lastItem={null}
                // firstItem={true ? undefined : null}
                // lastItem={true ? undefined : null}
                prevItem={true ? undefined : null}
                nextItem={true ? undefined : null}
              />
            </Segment>
          </Container>
        </Segment>
      </TritonDefaultLayout>
    </>
  )
}

// export const Head = () => <Seo title={'All Events'} />

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allEventCalendarItem(
      skip: $skip
      limit: $limit
      filter: {eventDateIsExpired: {eq: "false"}}
      sort: {order: ASC, fields: eventDate}
    ) {
      edges {
        node {
          contentItemId
          contentType
          displayText 
          path
          eventDate
          eventEndDate
          allDayEvent
          eventLocation
          eventDescription
          eventLink {
            text
            url
          }
          eventImage {
            urls
            imageAbsoluteUrls
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default PaginationIndexEvents
